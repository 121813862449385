import React from 'react'
import Ticketoverview from './Pages/overview'
import Issue from './Pages/issue'
import NewIssue from './Pages/newIssue'
import EditIssue from './Pages/editIssue'
import LabelEdit from './Pages/LabelEdit'
import { LanguageSwitcherValue } from './components/LanguageSwitcher'
import Dashboard from "./new/Pages/Dashboard";
import MyProjects from "./new/Pages/MyProjects";
import MyTask from "./new/Pages/MyTask";
import Chat from "./new/Pages/chat";
import SingleTask from "./new/Pages/SingleTask";
import MyUnderProjects from "./new/Pages/MyUnderProjects";
import MyTaskCustomer from "./new/Pages/MyTaskCustomer";
import MytaskUser from "./new/Pages/MytaskUser";
import Reports from "./new/Pages/reports";
import CPS from "./new/Pages/CPS";
import ActivityRecordingOverview from "./new/Pages/overview";

export const TicketPagesConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
            return [
                {
                    path: '/ticketdashboard/overview/:page',
                    elements: <Dashboard value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/myprojects/new/projects/:page',
                    elements: <MyProjects value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/myprojects/new/projects/:page/:projectid/:name',
                    elements: <MyUnderProjects value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/myprojects/task/all/projects/:id/:name',
                    elements: <MyTask value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/reports/TT/overview',
                    elements: <Reports value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/cps/overview',
                    elements: <CPS value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/myprojects/task/single/:id/:taskid',
                    elements: <SingleTask value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/MytaskUser/overview/1',
                    elements: <MytaskUser value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/ticket/Issue/:id',
                    elements: <Issue value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/activityrecording/overview/:page',
                    elements: <ActivityRecordingOverview value={value} />,
                    roles: ['superadmin', 'admin', 'merchant']
                },
                {
                    path: '/ticket/edit/Issue/:id',
                    elements: <EditIssue value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    path: '/ticket/new/Issue',
                    elements: <NewIssue value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },
               /* {
                    path: '/chat/new/ticketsystem/overview/1',
                    elements: <Chat value={value} />,
                    roles: ['superadmin', 'admin','teamtakt']
                },*/
            ]
        default:
            if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
                && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
                return [
                    {
                        path: '/myprojects/task/all/projects/:id/:name',
                        elements: <MyTaskCustomer value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/myprojects/task/single/:id/:taskid',
                        elements: <SingleTask value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    }
                ]
            } else {
                return [
                    {
                        path: '/ticket/overview/:page',
                        elements: <Ticketoverview value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/ticket/Issue/:id',
                        elements: <Issue value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/ticket/edit/Issue/:id',
                        elements: <EditIssue value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    },
                    {
                        path: '/Label/ticket/overview',
                        elements: <LabelEdit value={value} />,
                        roles: ['superadmin', 'admin','teamtakt']
                    }
                ]
            }

    }
}

export const TicketMenuConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
           return [
               {
                   header: 'IT-Support',
                   icon: 'support',
                   roles: ['superadmin', 'admin','teamtakt'],
                   menuitem: [
                       {
                           name: 'Dashboard',
                           route: '/ticketdashboard/overview/1',
                           icon: 'developer_board',
                           roles: ['superadmin', 'admin','teamtakt']
                       },
                       {
                           name: 'Projects',
                           route: '/myprojects/new/projects/1',
                           icon: 'note_alt',
                           roles: ['superadmin', 'admin','teamtakt']
                       },
                       {
                           name: 'My Tasks',
                           route: '/MytaskUser/overview/1',
                           icon: 'chat',
                           roles: ['superadmin', 'admin','teamtakt']
                       },
                       {
                           name: 'Leistung erfassen',
                           route: '/activityrecording/overview/1',
                           icon: 'more_time',
                           roles: ['superadmin', 'admin', 'merchant']
                       },
                       {
                           name: 'Reports',
                           route: '/reports/TT/overview',
                           icon: 'query_stats',
                           roles: ['superadmin', 'admin','teamtakt']
                       },
                       {
                           name: 'General Settings',
                           route: '/cps/overview',
                           icon: 'settings',
                           roles: ['superadmin', 'admin','teamtakt']
                       },
                      /* {
                           name: 'Chat',
                           route: '/chat/new/ticketsystem/overview/1',
                           icon: 'settings',
                           roles: ['superadmin', 'admin','teamtakt']
                       },*/
                   ]
               }
           ]
        default:
            if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
            && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
                return [
                    {
                        header: LanguageSwitcherValue('IT-Support'),
                        icon: 'support',
                        roles: ['superadmin', 'admin','teamtakt'],
                        menuitem: [
                            {
                                name: 'My Project',
                                route: '/myprojects/task/all/projects/'+process.env.REACT_APP_MODE_TICKET_ID+'/'+process.env.REACT_APP_MODE_TICKET_NAME+'',
                                icon: 'note_alt',
                                roles: ['superadmin', 'admin','teamtakt']
                            }
                        ]
                    }
                ]
            } else {
                return [
                    {
                        header: LanguageSwitcherValue('IT-Support'),
                        icon: 'support',
                        roles: ['superadmin', 'admin','teamtakt'],
                        menuitem: [
                            {
                                name: 'Dashboard',
                                route: '/ticket/overview/:page',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin','teamtakt']
                            },   {
                                name: "Labels",
                                route: '/Label/ticket/overview',
                                icon: 'label',
                                roles: ['superadmin', 'admin','teamtakt']
                            }
                        ]
                    }
                ]
            }

    }
}

export const TicketSingleMenuConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
            return [
                        {
                            name: 'Dashboard',
                            route: '/ticketdashboard/overview/1',
                            icon: 'developer_board',
                            roles: ['superadmin', 'admin','teamtakt']
                        },
                        {
                            name: 'Projects',
                            route: '/myprojects/new/projects/1',
                            icon: 'note_alt',
                            roles: ['superadmin', 'admin','teamtakt']
                        }

            ]
        default:
            if(process.env.REACT_APP_MODE_TICKET_ID !== undefined && process.env.REACT_APP_MODE_TICKET_ID !== null
                && process.env.REACT_APP_MODE_TICKET_NAME !== undefined && process.env.REACT_APP_MODE_TICKET_NAME !== null) {
                return [
                            {
                                name: 'Supportsystem',
                                route: '/myprojects/task/all/projects/'+process.env.REACT_APP_MODE_TICKET_ID+'/'+process.env.REACT_APP_MODE_TICKET_NAME+'',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin','teamtakt']
                            }

                ]
            } else {
                return [
                            {
                                name: 'Dashboard',
                                route: '/ticket/overview/:page',
                                icon: 'developer_board',
                                roles: ['superadmin', 'admin','teamtakt']
                            },   {
                                name: "Labels",
                                route: '/Label/ticket/overview',
                                icon: 'label',
                                roles: ['superadmin', 'admin','teamtakt']
                            }
                        ]

            }

    }
}


export const itSupport = () => {

    switch (process.env.REACT_APP_MODE_TICKET) {
        case 'new':
            return [
                {
                    title: 'menu.item.dashboard',
                    href: '/ticketdashboard/overview/1',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'developer_board',
                    text: 'menu.item.dashboard.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'Projects',
                    href: '/myprojects/new/projects/1',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'note_alt',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'MyTask',
                    href: '/MytaskUser/overview/1',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'chat',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'General Settings',
                    href: '/cps/overview',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'settings',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
              /*  {
                    title: 'Chat',
                    href: '/chat/new/ticketsystem/overview/1',
                    undertitle: LanguageSwitcherValue('IT-Support'),
                    icon: 'chat',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin']
                }*/
            ]
        default:
            return [
                {
                    title: 'menu.item.dashboard',
                    href: '/ticket/overview/1',
                    undertitle: 'IT-Support',
                    icon: 'developer_board',
                    text: 'menu.item.dashboard.description',
                    roles: ['superadmin', 'admin','teamtakt']
                },
                {
                    title: 'menu.item.labels',
                    href: '/Label/ticket/overview',
                    undertitle: 'IT-Support',
                    icon: 'label',
                    text: 'menu.item.labels.description',
                    roles: ['superadmin', 'admin','teamtakt']
                }
            ]
    }
}


export const itSupportSidebar: string =
    '/ticket/overview/:page,/Label/ticket/overview'
