import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import {MIcon} from "../index";

interface IndexProps{
    backFunc?:void
    orderFunc?:void
    chatFunc?:void
    text?:string
    header?:string
    backButtonLabel?:string
    orderButtonLabel?:string
    chatButtonLabel?:string
}

const Index = (props:IndexProps) => {

    return (

            <Modal
                show={true}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title dangerouslySetInnerHTML={{__html: props.header as string}} />
                </Modal.Header>

                <Modal.Body>
                    <p dangerouslySetInnerHTML={{__html: props.text as string}} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary">{props.backButtonLabel}</Button>
                    <Button variant="success" className={'float-end'}>{props.chatButtonLabel}</Button>
                    <Button variant="danger" className={'float-end'}>{props.orderButtonLabel}</Button>
                </Modal.Footer>
            </Modal>

    )
}

export default Index;
