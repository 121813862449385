import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Chart } from "react-google-charts";
import'../css/dashboardTicketSystem.css'
import CreateProjectCard, {CreateTaskCard} from "../components/CreateProjectCard";
import CreateBlock from "../components/CreateBlock";
import {ResponseDashbaordProject, ResponseDashbaordTask, ResponseDashboardProject} from "../../../API/response/ticket";
import '../css/timeline.css'
import {between2digits, Search} from "./MyProjects";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import setNavi from "../../../../../@WUM/core/component/const/setNavi";
import {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";

const Dashboard = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    setNavi({
        route:'/ticketdashboard/overview/'
    })
    const response = ResponseDashboardProject();
    const Project = ResponseDashbaordProject(0);
    const Tasks = ResponseDashbaordTask(0)
    const navigate = useNavigate();
    if (typeof response !== "boolean" && response?.length > 0) {



        let buildDashboardCardPRoject = [];
        if (Project !== true && typeof Project !== "boolean" && Project?.length > 0) {

            for (let x = 0; x < Project.length; x++) {
                let nowcal: number = (100 / (Project[x].allTask as number)) * (Project[x].doneTask as number)
                buildDashboardCardPRoject.push(
                    <CreateProjectCard Store={Store} id={Project[x].id} title={Project[x].title}
                                       now={(!isNaN(nowcal) ? nowcal.toFixed(0) : 0)}
                                       variant={between2digits((!isNaN(nowcal) ? nowcal : 0))}
                                       status={'Open'} ClassN={'col-6'}
                                       daysColor={'14, 170, 11'} daysLeft={12} allTask={Project[x].allTask}
                                       task={Project[x].doneTask} date={Project[x].created_at}/>
                )
            }
        }

        let buildDashboardCardTask = [];
        if (Tasks !== true && typeof Tasks !== "boolean" && Tasks?.length > 0) {

            for (let x = 0; x < Tasks.length; x++) {
                buildDashboardCardTask.push(<>
                    <CreateTaskCard Store={Store} ProjectId={Tasks[x].project_id} priorität={Tasks[x].priority}
                                    position={(Tasks[x].position !== null ? Tasks[x].position : x)}
                                    comment={Tasks[x].comment} ClassN={'col-6'} ColPadding={'0px 12px '}
                                    id={Tasks[x].task_id} avatarID={1} media={Tasks[x].media}
                                    title={Tasks[x].regard} now={100} variant={'success'}
                                    status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={1}
                                    allTask={1} archiv={Tasks[x].archiv} Due_On={Tasks[x].due_on}
                                    label={Tasks[x].support_label} delete={Tasks[x].delete}
                                    teamMember={true} date={Tasks[x].created_at} text={Tasks[x].instanz}
                                    background={(Tasks[x].instanz !== undefined && Tasks[x].instanz !== null && Tasks[x].instanz !== '' ? '#e5f1fd' : (Tasks[x].color !== undefined && Tasks[x].color !== null ? Tasks[x].color : 'white'))}/>
                </>)

            }
        }

        let NewPieDate = [];
        let legendenholder = [];
        for(let cx =0;cx<response[0].AllColumnProject.length;cx++) {
            if(response[0].AllColumnProject[cx] !== null && response[0].AllColumnProject[cx] !== undefined) {
                let tmpDatas = [response[0].AllColumnProject[cx].name, response[0].ProjectColoumn[response[0].AllColumnProject[cx].name],response[0].AllColumnProject[cx].color]
                NewPieDate.push(tmpDatas)

                legendenholder.push(
                    <>
                        <div style={{borderBottom: '1px solid '+response[0].AllColumnProject[cx].color, marginBottom: '10px'}}>
                            <div style={{
                                borderLeft: '5px solid '+response[0].AllColumnProject[cx].color,
                                borderRadius: '0px',
                                paddingLeft: '10px',
                                borderBottomLeftRadius: '5px',
                                borderTopLeftRadius: '5px'
                            }}>
                                <p className={'m-0'}>{IntlMessageValue('de', 'pie.project.'+response[0].AllColumnProject[cx].name, Store)}</p>
                                <p className={'float-start m-0'}><strong>{response[0].ProjectColoumn[response[0].AllColumnProject[cx].name]}</strong></p><p className={'float-end m-0'}>
                                <strong>{((100 / (response[0].TotalActiveProjects as number) ) * (response[0].ProjectColoumn[response[0].AllColumnProject[cx].name] as number)).toFixed(1)}%</strong></p>
                                <br style={{clear: 'both', height: '1px'}}/>
                            </div>
                            <br/>
                        </div>
                    </>
                )

            }

        }

        let legendenholderTask = [];
        let NewPieDateTask = [];
        for(let cx =0;cx<response[0].AllColumnTask.length;cx++) {
            if(response[0].AllColumnTask[cx] !== null && response[0].AllColumnTask[cx] !== undefined) {
                let tmpDatas = [response[0].AllColumnTask[cx].name, response[0].TaskColumn[response[0].AllColumnTask[cx].name],response[0].AllColumnTask[cx].color]
                NewPieDateTask.push(tmpDatas)
                legendenholderTask.push(
                    <>
                        <div style={{borderBottom: '1px solid '+response[0].AllColumnTask[cx].color, marginBottom: '10px'}}>
                            <div style={{
                                borderLeft: '5px solid ' + response[0].AllColumnTask[cx].color,
                                borderRadius: '0px',
                                paddingLeft: '10px',
                                borderBottomLeftRadius: '5px',
                                borderTopLeftRadius: '5px'
                            }}>
                                <p className={'m-0'}>{IntlMessageValue('de', 'pie.task.' + response[0].AllColumnTask[cx].name, Store)}</p>
                                <p className={'float-start m-0'}>
                                    <strong>{response[0].TaskColumn[response[0].AllColumnTask[cx].name]}</strong></p><p
                                className={'float-end m-0'}>
                                <strong>{((100 / (response[0].totalTask as number)) * (response[0].TaskColumn[response[0].AllColumnTask[cx].name] as number)).toFixed(1)}%</strong>
                            </p>
                                <br style={{clear: 'both', height: '1px'}}/>
                            </div>
                            <br/>
                        </div>
                    </>
                )
            }
        }
        const Legende = (
            <>
                <div className={'legende'}>
                    {legendenholder}

                </div>

            </>
        )

        const LegendeTask = (
            <>
                <div className={'legende'}>

                    {legendenholderTask}
                </div>

            </>
        )
        return (
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                    <Search navigate={navigate}/>
                    <Row>
                        <Col xxl={'3'} xl={'6'} lg={'6'} md={'12'} sm={'12'} className={'dashbaordInfoCards'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.lastproject30pastdays', Store)}
                                         icon={'format_list_numbered'} number={response[0].last30daysProject}/>
                        </Col>
                        <Col xxl={'3'} xl={'6'} lg={'6'} md={'12'} sm={'12'} className={'dashbaordInfoCards'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.archivedProjects', Store)}
                                         icon={'history'} number={response[0].ArchivedProject}/>
                        </Col>
                        <Col xxl={'3'} xl={'6'} lg={'6'} md={'12'} sm={'12'} className={'dashbaordInfoCards'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.deletedProjects', Store)}
                                         icon={'format_list_numbered'} number={response[0].DeletedProject}/>
                        </Col>
                        <Col xxl={'3'} xl={'6'} lg={'6'} md={'12'} sm={'12'} className={'dashbaordInfoCards'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.numberofTasks', Store)}
                                         icon={'format_list_numbered'} number={response[0].totalTask}/>
                        </Col>

                    </Row>
                    <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                        <Col xxl={'6'} xl={'6'} lg={'12'} md={'12'} sm={'12'} className={'pl-0 dashbaordCardLeft'}>
                            <div className={'card dashbaordCardLeftinner'} style={{marginRight: '10px',padding:'10px'}}>
                            <h5 style={{paddingTop: '10px'}}>{IntlMessageValue('de', 'dashboard.last4project', Store)}</h5>
                            <Row>
                                {buildDashboardCardPRoject}
                            </Row>
                            </div>
                        </Col>
                        <Col xxl={'6'} xl={'6'} lg={'12'} md={'12'} sm={'12'}  className={'pr-0 dashbaordCard'}
                             style={{marginLeft: '0px'}}>
                            <div className={'card dashbaordCard'} style={{ padding: '10px'}}>
                                <Row>
                                    <Col xxl={'6'} xl={'6'} lg={'6'} md={'6'} sm={'12'}>

                                        <CreatePieChart date={NewPieDate} namespace={'AllProject'} stroke={3}
                                                        height={'100%'} width={'100%'}/>
                                    </Col>
                                    <Col xxl={'6'} xl={'6'} lg={'6'} md={'6'} sm={'12'}> {Legende}</Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '2em', marginLeft: '-12px', marginRight: '-12px'}}>
                        <Col xxl={'4'} xl={'6'} lg={'6'} md={'12'} sm={'12'} className={'dashbaordInfoCards'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.newtaskpast30days', Store)}
                                         border={"1px solid #23AAE1"} icon={'format_list_numbered'}
                                         number={response[0].last30daysTasks}/>
                        </Col>
                        <Col xxl={'4'} xl={'6'} lg={'6'} md={'12'} sm={'12'} className={'dashbaordInfoCards'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.archivedTasks', Store)}
                                         border={"1px solid #23AAE1"} icon={'history'} number={response[0].ArchivedTask}/>
                        </Col>
                        <Col xxl={'4'} xl={'6'} lg={'6'} md={'12'} sm={'12'} className={'dashbaordInfoCards'}>
                            <CreateBlock title={IntlMessageValue('de', 'dashboard.deletedTasks', Store)}
                                         border={"1px solid #23AAE1"} icon={'format_list_numbered'}
                                         number={response[0].DeletedTask}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                        <Col xxl={'6'} xl={'6'} lg={'12'} md={'12'} sm={'12'} className={'pl-0 dashbaordCardLeft'}
                        >
                            <div className={'card dashbaordCardLeftinner'} style={{marginRight: '10px', padding: '10px',border: "1px solid #23AAE1"}}>
                                <h5 style={{paddingTop: '10px'}}>{IntlMessageValue('de', 'dashboard.last6task', Store)}</h5>
                                <Row>
                                    {buildDashboardCardTask}
                                </Row>
                            </div>
                        </Col>
                        <Col xxl={'6'} xl={'6'} lg={'12'} md={'12'} sm={'12'}  className={'pr-0 dashbaordCard'}>
                            <div className={'card dashbaordCard'} style={{padding: '10px',border: "1px solid #23AAE1"}}>
                                <Row>
                                    <Col xxl={'6'} xl={'6'} lg={'6'} md={'6'} sm={'12'}>
                                        <CreatePieChart date={NewPieDateTask} namespace={'allTask'} stroke={3}
                                                        height={'100%'} width={'100%'}/>
                                    </Col>
                                    <Col> {LegendeTask}</Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>


                </div>
            </>
        )
    } else {
        return (<></>)
    }
}

export default Dashboard;


const CreatePieChart = (props:{date:any,namespace:any,stroke:any,width:any,height:any}) => {

    let summ = 0;

    for(let np=0;np<props.date.length;np++){
        summ = summ + props.date[np][1];
    }

    for(let np=0;np<props.date.length;np++){
        props.date[np].push((props.date[np][1]/summ*100).toFixed(0))
    }

    const biggerCircle = (e:any) => {

        let OhterCirlce = document.getElementsByClassName('circlePie'+ props.namespace);

        for(let c = 0;c<OhterCirlce.length;c++) {
            (OhterCirlce[c] as SVGCircleElement).style.strokeWidth = '3'
        }
        (e.target as SVGCircleElement).style.strokeWidth = '5'
    }

    let result = [];
    let svgHolde = [];
    for(let np=0;np<props.date.length;np++){

        result.push(
            {name: props.date[np][0], count: props.date[np][1], color: hexToRGB(props.date[np][2])}
        )

        svgHolde.push(
            <>
                <circle className={'circlePie'+ props.namespace} id={"donut-segment" + (np + 1) + props.namespace} cx="21" cy="21" r="15.91549430918954"
                        fill="transparent"
                        stroke={props.date[np][2]}
                        strokeWidth={props.stroke} strokeDasharray="0 100" strokeDashoffset="25">
                    <title>{props.date[np][0]}</title>

                </circle>
            </>
        )


    }

    const CalculatePie = (result: any) => {

        let persum = 0;

        for (let x = 0; x < result.length; x++) {
            let val = result[x].count
            var totalValue = document.getElementById('totalValue'+props.namespace)
            var total = summ;
            let per = val/total*100;
            var offset = 25;
            if(totalValue !== null) {
                totalValue.textContent = String(total);
            }
            var valD = document.getElementById('donut-segment'+(x+1)+props.namespace)
            if(valD !== null) {
                valD.style.transition = "stroke-dasharray 0.5s ease-in-out, stroke-dashoffset 0.5s ease-in-out";
                valD.style.strokeDasharray = per+" "+(100-per);
                valD.style.strokeDashoffset = String(100 - persum + offset);
            }
            persum = persum + per;
        }
    }

    CalculatePie(result);



    return (
        <>
            <svg className="donut" width={props.width} height={props.height} viewBox="0 0 42 42">
                <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>

                {svgHolde}

                <g className="chart-text">
                    <text x="50%" y="50%" className="chart-number" id={"totalValue"+props.namespace}>
                        0
                    </text>
                    <text x="50%" y="50%" className="chart-label">
                        Total
                    </text>
                </g>
            </svg>
        </>
    )

}

const PieChart = (props:{date:any}) => {

    let summ = 0;

    for(let np=0;np<props.date.length;np++){
        summ = summ + props.date[np][1];
    }

    for(let np=0;np<props.date.length;np++){
        props.date[np].push((props.date[np][1]/summ*100).toFixed(0))
    }
    let result = [];
    for(let np=0;np<props.date.length;np++){
        result.push(
            {name: props.date[np][0], count: props.date[np][1], color: hexToRGB(props.date[np][2])}
        )
    }

    let results = result;

    let cxs = (document.querySelector(".arc") as HTMLCanvasElement);
    let total = summ;
    if (cxs !== null) {
        let cx = cxs.getContext("2d")
        let currentAngle = -0.5 * Math.PI;
        results.forEach(function (result) {
            let sliceAngle = (result.count / total) * 2 * Math.PI;
            if (cx !== null) {
                cx.beginPath();
                cx.arc(100, 100, 100,
                    currentAngle, currentAngle + sliceAngle);
                currentAngle += sliceAngle;
                cx.lineTo(100, 100);
                cx.fillStyle = result.color;
                cx.fill();
            }
        });
    }

    return (
        <>
            <canvas width="200" height="200" className="arc canvas"
                    style={{border: "none", backgroundImage: "none"}}></canvas>
        </>
    )
}


const hexToRGB = (h: any) => {
    let r: any = 0,
        g: any = 0,
        b: any = 0;

    // 3 digits
    if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

        // 6 digits
    } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
    }


    return RGBToHSL(r, g, b);
}

const RGBToHSL = (r: any, g: any, b: any) => {
    // Make r, g, and b fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;

    // Find greatest and smallest channel values
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;
    // Calculate hue
    // No difference
    if (delta == 0)
        h = 0;
    // Red is max
    else if (cmax == r)
        h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax == g)
        h = (b - r) / delta + 2;
    // Blue is max
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0)
        h += 360;
    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return "hsl(" + h + "," + s + "%," + l + "%)";
}
