
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React, {useState,useEffect} from 'react'
import {AvatarName, CreateTaskCard} from "../components/CreateProjectCard";
import appendIcon from "../components/appendIcon";
import {IntlMessage, IntlMessageValue} from "../../../../../@WUM/core/Elements/";
import DynamikModal, {DynamikOffCanvas} from "../../../../../@WUM/core/component/const/DynamikModal";
import {useSelector} from "react-redux";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";
import PielersForm from '../../../../../@WUM/core/form-serilizer/index'
import Button from 'react-bootstrap/Button';
/* @ts-ignore */
import { Task } from "gantt-task-react";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    ResponseAllCPS,
    ResponseAllLastActivities, ResponseAllLastActivitiesProject, ResponseAllPrio,
    ResponseAllTask,
    ResponseAllTaskColumn,
    ResponseAllTaskSwimelanes,
    ResponseAllTResponsibility, ResponseSingleProject
} from "../../../API/response/ticket";
import SunEditorConfig from "../../components/SuneditorConfig";
import SunEditorBig from "../../../../../@WUM/core/component/const/SunEditorBig";
import {BoardTableSwitcher, FilterResponse, Search, TableMakerSupport} from "./MyProjects";
import {closeEsc, collapse, refresh, switchdisplay} from "./SingleTask";
import InputGroup from "react-bootstrap/InputGroup";
import '../css/timeline.css'
import Sortable from 'sortablejs'
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import ReactDOM from "react-dom";
import Avatar from "@material-ui/core/Avatar";
import Card from 'react-bootstrap/Card';
import MDEditor from "@uiw/react-md-editor";
import MarkdownEditor from '@uiw/react-markdown-editor';
import SunEditor from "suneditor-react";
import SendMessage, {
    FetchwithMattermostANDsuccessForm,
    FetchwithMattermostANDsuccessFormWithClose
} from "../components/Mattermost";
import {
    notify_save
} from '../../../../../@WUM/core/component/const/notification.service'
import {CirclePicker, SwatchesPicker, TwitterPicker} from 'react-color';
/* @ts-ignore */
import {Calendar, momentLocalizer, stringOrDate} from 'react-big-calendar'
/* @ts-ignore */
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Chart } from "react-google-charts";
import setNavi from "../../../../../@WUM/core/component/const/setNavi";
import date from "../../../../../@WUM/core/tablemaker/elements/date";
import CreateGrantDiagramm from "../components/Grant";
import {error} from "../../../../../@WUM/core/component/const/error.services";

const MyTask = (props:{value:any}) => {
    const { id,name } = useParams<{ id: string,name:string }>()
    let ProjectId = id;
    setNavi({
        route:'/myprojects/task/all/projects/'
    })
    const [fetchData, setFetchData] = useState(0);
    const [archiv, setArchiv] = useState(0);
    const [del, setDel] = useState(0);
    const [calender, setCalender] = useState(0);
    let response = ResponseAllTask(id,fetchData,archiv,del)
    let Column = ResponseAllTaskColumn(fetchData)
    let Swimelanes = ResponseAllTaskSwimelanes(id,fetchData)
    let AllUSer = ResponseAllTResponsibility(fetchData)
    let UserHolder = [];
    const singleProject = ResponseSingleProject(ProjectId);
    const [value, setValue] = useState("");
    const navigate = useNavigate();
    const LastActivities = ResponseAllLastActivitiesProject(id,fetchData)
    let BuildLastActivities = [];
    const AllCPS = ResponseAllCPS(fetchData);
    let ColorHolder:any = [];
    const Prio = ResponseAllPrio(fetchData)
    if (typeof AllCPS !== "boolean" && AllCPS?.length > 0) {
        for (let c = 0; c < AllCPS.length; c++) {
            ColorHolder.push({color:AllCPS[c].color,title:AllCPS[c].title})

        }
    }
    if (typeof LastActivities !== "boolean" && LastActivities?.length > 0) {
        for (let la = 0; la < LastActivities.length; la++) {

            if(LastActivities[la].system === '9') {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5" style={{padding:'10px'}}>
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src={'data:image/png;base64,'+LastActivities[la].media} />
                                <span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <span className={'float-end pr-3 btn btn-info'} style={{cursor:'default',background:'#23AAE1',color:'white'}}><small><strong>Extern</strong></small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/myprojects/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button>
                            </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            } else {
                BuildLastActivities.push(
                    <>
                        <li className="timeline-item mb-5">
                            <h5 className="fw-bold"><Avatar className={'float-start'} alt={LastActivities[la].member} src={'data:image/png;base64,'+LastActivities[la].media}>
                                {AvatarName(LastActivities[la].member)}
                            </Avatar><span className={'float-start'} style={{paddingTop:'5px',paddingLeft:'5px'}}><small>{LastActivities[la].member}</small></span>
                                <Button variant="primary" className={"pr-3 float-end"} style={{padding:'5px',background:'white',borderColor:'#23AAE1',color:'#23AAE1'}}>
                                    <span className="material-icons" style={{verticalAlign:'middle',marginLeft:'13px'}} onClick={()=>navigate('/myprojects/task/single/'+LastActivities[la].project_id+'/'+LastActivities[la].task_id)}>
                                    logout
                                </span></Button> </h5>
                            <br style={{clear:'both'}}/>
                            <p className="text-muted mb-2 fw-bold">{LastActivities[la].regard}<br/><small>{LastActivities[la].created_at}</small></p>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: LastActivities[la].content}} style={{padding:'15px',borderRadius:'10px',border:'1px solid #E5E5E5'}} />
                        </li>

                    </>
                )
            }
        }
    }
    const SearchUserID = (username:any) => {
        if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
            for (let us = 0; us < AllUSer?.length; us++) {
                if(username as string === AllUSer[us].username) {
                    return AllUSer[us].id
                }
            }
        }
        return 0;
    }
    if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {
        for (let us = 0; us < AllUSer?.length; us++) {
            UserHolder.push(
                <>
                    <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                </>
            )
        }
    }

    const [fetchSortData, setFetchSortData] = useState([]);
    const Store = useSelector((state: any) => state)
    const [filter, setfilter] = useState([]);
    const [state, setState] = useState({
        background: '#fff',
    });
    let y = 1;
    /*let buildCreateTaskCardOpen = [];
    let buildCreateTaskCardInProgress = [];
    let buildCreateTaskCardInRewview = [];
    let buildCreateTaskCardCompleted = [];*/

    let buildSwimelanes = [];



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectcolumn, setSelectcolumn] = useState(0);
    const [selectswimelane, setswimelane] = useState(0);
    let  firstswimelane;
    const CloseDynmaikModal = () => {
        handleClose();
        setSelectcolumn(0)
        setswimelane(0)
    }

    const openNew = (columnID:any,SwimlaneID:any) => {
        handleShow()
        setSelectcolumn(columnID)
        setswimelane(SwimlaneID)
    }



    const EditSwimelane = (id:any,color:any,name:any) => {
        return(<>

        <div className="modal-dialog">
            <Form
                id={'form001'}
                className={'formControl'}
                noValidate
                onSubmit={(e) =>
                    editSwimelane(e,CloseMember,'addDialogdynamik')
                }
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">Edit swimlane</div>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}/>
                    </div>
                    <div className="modal-body" id={'FormBody'}>
                        <Row>
                            <Col className={'col-10'}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" id={'editSwimelanename'} name={'name'} required={true} defaultValue={name}/>
                            </Form.Group></Col>
                            <Col className={'col-2'}> <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
                                <Form.Control
                                    type="color"
                                    name={'color'}
                                    id={'editSwimelanecolor'}
                                    defaultValue={color}
                                /></Col>
                        </Row>
                        <input name={'id'} type="hidden" id="id" className="form-control" defaultValue={id} />
                        <AddedColor state={state} Store={Store} cid={'editSwimelanecolor'} color={ColorHolder} setState={setState} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => CloseMember('addDialogdynamik',[{id:'editSwimelanename',value:''},{id:'editSwimelanecolor',value:'#10B82B'}])}>Close
                        </button>
                        <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >Edit swimlane</button>
                    </div>


                </div>
            </Form>
        </div>
            </>
    )}
    const editSwimelane = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/edit/swimelanes/'+formdata.get('id')+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successFormswimelane(result,CloseMember,CloseMemberID,[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}]))
            .catch((error) => errorFormswimelane(error,CloseMember,CloseMemberID))
    }
    if(Swimelanes !== true && typeof Swimelanes !== "boolean" && Swimelanes?.length > 0) {


        let Swimelane = [];
        firstswimelane = Swimelanes[0].id
        for(let sw = 0; sw < Swimelanes.length; sw++) {
            let TMPbuildCreateTaskCard = [];
            let SwimelaneCounter = 0;
            let Swimelaneid = Swimelanes[sw].id;
            let background = Swimelanes[sw].color



            if (Column !== true && typeof Column !== "boolean" && Column?.length > 0
            ) {
                let TMPCreateTaskCard = [];

                for (let c = 0; c < Column.length; c++) {
                    let buildCreateTask = [];
                    let counter = 0;

                    if (response !== true && typeof response !== "boolean" && response?.length > 0
                    ) {
                        let Data = (fetchSortData.length === 0 ? response : fetchSortData);
                        for (let x = 0; x < Data.length; x++) {
                            if (Data[x].column.id === Column[c].id && Swimelanes[sw].id == Data[x].swimlane_id) {
                                counter++
                                SwimelaneCounter = SwimelaneCounter + 1
                                buildCreateTask.push(<>
                                    <CreateTaskCard Store={Store} media={Data[x].media} ProjectId={Data[x].project_id}  swimelane={Swimelanes[sw].id} priorität={Data[x].priority}
                                                    position={(Data[x].position !== null ? Data[x].position : x)} comment={Data[x].comment}
                                                    id={Data[x].task_id} avatarID={1} state={(Data[x].status !== undefined && Data[x].status !== null ? Data[x].status.state : 0)}
                                                    title={Data[x].regard} now={100} variant={'success'}
                                                    status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={1} Due_On={Data[x].due_on}
                                                    allTask={1} archiv={Data[x].archiv} setFetchData={setFetchData} responsibility={Data[x].responsibility}
                                                    label={Data[x].support_label} delete={Data[x].delete}
                                                    teamMember={true} date={Data[x].created_at} text={Data[x].instanz}
                                                    background={(Data[x].instanz !== undefined && Data[x].instanz !== null && Data[x].instanz !== '' ? '#e5f1fd' : (Data[x].color !== undefined && Data[x].color !== null ? Data[x].color : 'white'))}/>
                                </>)
                            }
                        }
                    }

                    let cid = Column[c].id;

                    TMPCreateTaskCard.push(
                        <>

                            <Col style={{minWidth:'285px'}}>
                                <h6 className={'text-uppercase'} style={{
                                    borderBottom: '2px solid #F8621B',
                                    paddingBottom: '15px'
                                }}>{Column[c].name} ({counter})</h6>
                                <div className={'nested-sortable'} data-columnID={cid}  data-swimelane={Swimelanes[sw].id}
                                     style={{minHeight: (buildCreateTask.length > 0 ? 'auto' : '60px')}}>
                                    {buildCreateTask}
                                </div>
                                <br/>
                                <p onClick={() => openNew(cid,Swimelaneid)} className={'text-center'} style={{cursor:'pointer'}}>
                            <span  className="material-icons" style={{verticalAlign: 'bottom'}}>
                                add
                            </span>
                                    {IntlMessageValue('de', 'task.add.new', Store)}
                                </p>
                            </Col>

                        </>
                    )

                }
                TMPbuildCreateTaskCard.push(TMPCreateTaskCard)
            }


            const setterSwimelane = (id:any,closeAnother?:any,displayState?:any) => {
                switchdisplay(id,closeAnother,displayState)
                let swimelane = document.getElementById('swimelane_'+Swimelaneid) as HTMLDivElement;
                if(swimelane !== null) {
                    if (swimelane.style.display === 'none') {
                        let Icon = document.getElementById('expand_swimelane_' + Swimelaneid) as HTMLSpanElement;
                        if (Icon !== null) {
                            Icon.innerHTML = 'expand_more'
                        }
                    } else {
                        let Icon = document.getElementById('expand_swimelane_' + Swimelaneid) as HTMLSpanElement;
                        if (Icon !== null) {
                            Icon.innerHTML = 'expand_less'
                        }
                    }
                }
            }
            let swimlanename = Swimelanes[sw].name
            Swimelane.push(
                <>
                    <div className={'swimelane'} style={{background: background,borderRadius:'0px',marginLeft:'10px',width:'97.4%'}}>
                            <p  >
                                 <span className="material-icons float-start" style={{cursor:'pointer'}} id={'expand_swimelane_'+Swimelaneid} onClick={()=>setterSwimelane('swimelane_'+Swimelaneid,null,'flex')}>
                                    expand_less
                                </span>
                            <span className={'ml-1 float-start'} style={{cursor:'pointer'}} onClick={()=>setterSwimelane('swimelane_'+Swimelaneid,null,'flex')}>{swimlanename} ({SwimelaneCounter})</span>

                                <Button variant="primary" className={'btn-sm float-end'} style={{height:'20px',width:'20px',padding:'0px',marginTop:'2px'}}>
                            <span className="material-icons " style={{fontSize:'18px'}}
                                  onClick={(e) => AddedFunc2(EditSwimelane(Swimelaneid,background,swimlanename))}>
                            edit
                        </span></Button>
                        </p>
                    </div>

                    <div id={'swimelane_'+Swimelaneid}  style={{display:'flex',overflowY:'hidden',width: '98%',overflowX:'scroll'}}>
                        <Row className={'flex-nowrap'} style={{width: '100%'}}>
                        {TMPbuildCreateTaskCard}
                        </Row>
                    </div>
                </>
            )
        }
        buildSwimelanes.push(
            <>
                {Swimelane}
            </>
        )
    } else {
        let TMPCreateTaskCard = [];
        if (Column !== true && typeof Column !== "boolean" && Column?.length > 0
        ) {


            for (let c = 0; c < Column.length; c++) {
                let buildCreateTask = [];
                let counter = 0;

                if (response !== true && typeof response !== "boolean" && response?.length > 0
                ) {
                    let Data = (fetchSortData.length === 0 ? response : fetchSortData);
                    for (let x = 0; x < Data.length; x++) {



                        if (Data[x].column.id === Column[c].id) {
                            counter++
                            buildCreateTask.push(<>
                                <CreateTaskCard Store={Store} media={Data[x].media} ProjectId={Data[x].project_id} priorität={Data[x].priority}
                                                position={(Data[x].position !== null ? Data[x].position : x)}
                                                id={Data[x].task_id} avatarID={1}
                                                title={Data[x].regard} now={100} variant={'success'}
                                                status={'Open'} daysColor={'248, 98, 27'} daysLeft={2} task={1}
                                                allTask={1}
                                                teamMember={true} date={Data[x].created_at} text={Data[x].instanz}
                                                background={(Data[x].instanz !== undefined && Data[x].instanz !== null && Data[x].instanz !== '' ? '#e5f1fd' : 'white')}/>
                            </>)
                        }
                    }
                }

                let cid = Column[c].id;

                TMPCreateTaskCard.push(
                    <>
                        <Col>
                            <h6 className={'text-uppercase'} style={{
                                borderBottom: '2px solid #F8621B',
                                paddingBottom: '15px'
                            }}>{Column[c].name} ({counter})</h6>
                            <div className={'nested-sortable'} data-columnID={cid}  data-swimelane={null}
                                 style={{minHeight: (buildCreateTask.length > 0 ? 'auto' : '60px')}}>
                                {buildCreateTask}
                            </div>
                            <br/>
                            <p onClick={() => openNew(cid,0)} className={'text-center'} style={{cursor:'pointer'}}>
                            <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                                add
                            </span>
                                {IntlMessageValue('de', 'task.add.new', Store)}
                            </p>
                        </Col>
                    </>
                )

            }
        }
        buildSwimelanes.push(TMPCreateTaskCard)
    }



    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "regard": Data.regard,
                "swimlane_id": Data.swimlane_id,
                "project_id": Data.project_id,
                "support_type_id": Data.support_type_id,
                "status_id": Data.status_id,
                "task_id": Data.task_id,
                "member": Data.member,
                "description": Data.description,
                "due_on": Data.due_on,
                "priority": Data.priority,
                "responsibility": Data.responsibility,
                responsibilityID: SearchUserID(Data.responsibility),
                "color": Data.color,
                "column_id": Data.column_id,
                "creator": Data.creator
            }
        );

       var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        setValue('');
        fetch(ApiURL() +"yoptimize/new/task/", requestOptions)
            .then(response => response.text())
            .then(result => {
                let formfile = (document.getElementById('fileuploadAttachment') as HTMLInputElement).files
                if(formfile !== null &&  formfile?.length > 0) {

                    for (let f = 0; f < formfile?.length; f++) {
                        let formDatas = new FormData()
                        formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                        formDatas.append('context', 'pielers_element_text_image')
                        // @ts-ignore
                        formDatas.append(`file${0}`, formfile[f])
                        var requestOptions = {
                            method: 'POST',
                            body: formDatas
                        }
                        let link
                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                            link = process.env.REACT_APP_CDN_API_URL_LOCAL
                        } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                            link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                        } else if (process.env.REACT_APP_MODE === 'LIVE') {
                            link = process.env.REACT_APP_CDN_API_URL_LIVE
                        }

                        fetch(link + 'upload/', requestOptions)
                            .then((response) => response.text())
                            .then((results) => {

                                var raws = JSON.stringify({
                                    url: JSON.parse(results).cdn_url,
                                    name: JSON.parse(results).name,
                                    task_id: JSON.parse(result).task_id,
                                    project_id: id,
                                    member: localStorage.getItem('username'),
                                    type: JSON.parse(results).content_type
                                })

                                var requestOptionss = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization:
                                            'Bearer ' +
                                            JSON.parse(localStorage.getItem('token') as string)
                                    },
                                    body: raws
                                }
                                fetch(ApiRoot() + 'yoptimize/save/attachdocument/', requestOptionss)
                                    .then((response) => response.text())
                                    .then((result) => {
                                        successForm(result)
                                        SendMessage(
                                            '[Projekt: ***' + (singleProject !== true && typeof singleProject !== "boolean" && singleProject?.length > 0 ? singleProject[0].title : '') + '***](' + window.location.origin + '/myprojects/task/all/projects/' + id + '/system) \n \n :adult: ' + localStorage.getItem('username') + '\n \n Ticket mit Inhalt :computer:: \n```json\n ' + JSON.stringify(Data) + ' hinzugefügt \n```',
                                            '## Neues Ticket erstellt! :rolled_up_newspaper: \n')
                                    })
                                    .catch((e) => {
                                        error(e)
                                    })

                            })
                            .catch(error => errorForm(error));
                    }} else {
                    successForm(result)
                    SendMessage(
                        '[Projekt: ***' + (singleProject !== true && typeof singleProject !== "boolean" && singleProject?.length > 0 ? singleProject[0].title : '') + '***](' + window.location.origin + '/myprojects/task/all/projects/' + id + '/system) \n \n :adult: ' + localStorage.getItem('username') + '\n \n Ticket mit Inhalt :computer:: \n```json\n ' + JSON.stringify(Data) + ' hinzugefügt \n```',
                        '## Neues Ticket erstellt! :rolled_up_newspaper: \n')
                }
            })
            .catch(error => errorForm(error));


    };

    const successForm = (result:any) => {
        setFetchData(Date.now())
        setSelectcolumn(0)
        handleClose()
    }

    const errorForm = (error:any)=> {
        setSelectcolumn(0)
        handleClose()
    }

        const FormModal = [
            {
                formType: 'input',
                label: IntlMessageValue('de', 'common.title', Store),
                required: true,
                type: 'text',
                name: 'regard',
                id: "newCalenderTitle",
                value: ''
            },
            {
                formType: 'input',
                label: 'swimlane_id',
                type: 'hidden',
                name: 'swimlane_id',
                id: "newCalenderTitle",
                value: '2'
            },
            {
                formType: 'input',
                label: 'project_id',
                type: 'hidden',
                name: 'project_id',
                id: "newCalenderTitle",
                value: id
            },
            {
                formType: 'input',
                label: 'support_type_id',
                type: 'hidden',
                name: 'support_type_id',
                id: "newCalenderTitle",
                value: '4'
            },
            {
                formType: 'input',
                label: 'status_id',
                type: 'hidden',
                name: 'status_id',
                id: "newCalenderTitle",
                value: '2'
            },
            {
                formType: 'input',
                label: 'task_id',
                type: 'hidden',
                name: 'task_id',
                id: "newCalenderTitle",
                value: (response !== true && typeof response !== "boolean" && response?.length > 0 && response[0].task_id ? response[0].task_id.split('-')[0]+'-' :  (name != null ?  name.charAt(0).toLocaleUpperCase() + name.substr(-1).toLocaleUpperCase()+'-' : ''))
            },
            {
                formType: 'input',
                label: 'member',
                type: 'hidden',
                name: 'member',
                id: "newCalenderTitle",
                value: localStorage.getItem('username')
            }
        ]




    const AddedFunc = (taskID:any,ProjectID:any,indiId?:any) => {


        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.classList.add('show')
        Dialog.style.display = 'block';


        let Task = document.getElementById('TaskId') as HTMLInputElement;
        if(taskID !== null) {
            if(Task !== null) {
                Task.value = taskID;
            }
        }


        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(ProjectID !== null) {
            if(Project !== null){
                Project.value = ProjectID
            }
        }


    }

    const AddedFunc2 = (form?:any,footer?:any,mode?:any) => {


            let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
            Dialog.classList.add("show");
            Dialog.style.display = 'block';
            if(form !== undefined) {
                let newElement = form
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('injectModalTask') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            }
        }



    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogMember')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }
    }

    const setter = () => {



        for (let x = 1; x < 17; x++) {
            if(y === x) {
                if(document.getElementById(x + 'avatarGroupadded') as HTMLDivElement !== null) {

                } else
                if (document.getElementById(x + 'avatarGroupadded') as HTMLDivElement === null) {
                    appendIcon(true, x + 'avatarGroup',AddedFunc,ProjectId,1)

                }
            }
            y++;
        }
    }

    useEffect(setter, [setter]);
    useEffect(() => {
        let editorText = (document.getElementById('htmleditor') as HTMLDivElement)
        if(editorText !== null) {
            let text = (editorText.children[0] as HTMLTextAreaElement)
            if(text !== null) {
                text.id='descriptionTextarea'
            }
        }
        document.addEventListener('keyup', function (event) {
            if ( event.keyCode == 27 )   {
                closeEsc()
            }
        })
    })
    useEffect(() => {
        MoveElement()
    })
    useEffect(() => {

        let nestedSortables = document.getElementsByClassName('nested-sortable')
        for (var i = 0; i < nestedSortables.length; i++) {
            new Sortable(nestedSortables[i] as HTMLElement, {
                group: 'nested',
                animation: 150,
                fallbackOnBody: true,
                swapThreshold: 0.65,
                onEnd: (evt) => {
                    let newcid;
                    let oldcid;
                    let taskidmove;
                    let projectIDmove;

                    oldcid = ((evt.from as HTMLDivElement).dataset.columnid);
                    (evt.from as HTMLDivElement).style.minHeight = '60px';
                    newcid = ((evt.to as HTMLDivElement).dataset.columnid)

                    taskidmove = (evt.clone as HTMLDivElement).dataset.taskid

                    projectIDmove = (evt.clone as HTMLDivElement).dataset.projectid
                    if(oldcid === newcid) {
                        console.log('kein Fetch')
                    } else {
                        var raw = JSON.stringify({
                            lastupdatefrom: localStorage.getItem('username'),
                            column_id: newcid
                        })


                        var requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' +
                                    JSON.parse(localStorage.getItem('token') as string)
                            },
                            body: raw
                        }


                        fetch(ApiRoot()+'yoptimize/project/'+projectIDmove+'/setColumn/task/'+taskidmove+'/oldCid/'+oldcid+'/', requestOptions)
                            .then((response) => response.text())
                            .then((result) => console.log(result))
                            .catch((error) => console.log(error))
                    }


                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes

                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                            let positionColumn = (SortList[sort] as HTMLDivElement).dataset.columnid
                            let newPosition = (SortListElement[element] as HTMLDivElement).dataset.position
                            let PTaskid = (SortListElement[element] as HTMLDivElement).dataset.taskid
                            let PProjectid = (SortListElement[element] as HTMLDivElement).dataset.projectid
                            let Pswimlane_id = (SortListElement[element] as HTMLDivElement).dataset.swimelane

                            var rawPosition = JSON.stringify({
                                position: newPosition,
                                swimlane_id: Pswimlane_id
                            })
                            var requestOptionsPosition = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        JSON.parse(localStorage.getItem('token') as string)
                                },
                                body: rawPosition
                            }

                            fetch(ApiRoot()+'yoptimize/project/'+PProjectid+'/setPosition/task/'+PTaskid+'/Cid/'+positionColumn+'/', requestOptionsPosition)
                                .then((response) => response.text())
                                .then((result) => console.log(result))
                                .catch((error) => console.log(error))
                        }
                    }

                },
                onUpdate: (evt) => {

                    // Sortierung
                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes
                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                        }
                    }
                },

                onClone: (evt) => {

                    // Sortierung
                    let SortList = document.getElementsByClassName('nested-sortable')

                    for(let sort = 0; sort < SortList.length;sort++) {
                        let SortListElement = SortList[sort].childNodes
                        for(let element =0;element<SortListElement.length;element++){
                            (SortListElement[element] as HTMLDivElement).dataset.position = ""+element+"";
                            (SortListElement[element] as HTMLDivElement).dataset.swimelane = ""+(SortList[sort] as HTMLDivElement).dataset.swimelane+"";
                        }
                    }
                }
            })
        }


        var element = document.getElementById("top") as HTMLHeadElement;
        if(element !== null) {
            element.scrollIntoView({ block: "end", inline:"nearest"});
        }

    })

    const Configuration = {
        tableHeader: [
            <IntlMessage Store={Store} messageId="common.id" />,
            <IntlMessage Store={Store} messageId="common.title" />,
            <IntlMessage Store={Store} messageId="common.ticket.dueOn" />,
            <IntlMessage Store={Store} messageId="Instanz" />,
            <IntlMessage Store={Store} messageId="Column" />,
            <IntlMessage Store={Store} messageId="common.ticket.responsibility" />,
        ],
        tableHeaderType: ['integer','string','due_on','string','string','string'],
        tableContent: ['task_id','regard','due_on','instanz','column.name','responsibility']
    }

    const saveSwimelane = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            name: formdata.get('name'),
            color: formdata.get('color'),
            project_id:id
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/new/swimelanes/', requestOptions)
            .then((response) => response.text())
            .then((result) => successFormswimelane(result,CloseMember,CloseMemberID,[{id:'saveSwimelanename',value:''},{id:'saveSwimelanecolor',value:'#10B82B'}]))
            .catch((error) => errorFormswimelane(error,CloseMember,CloseMemberID))
    }

    const successFormswimelane = (result:any,close:any,mode?:any,indiclear?:any) => {
        setFetchData(Date.now())
        notify_save()
        if(mode !== undefined && mode !== null) {
            close(mode,indiclear)
        } else {
            close()
        }

    }

    moment.locale("de")
    moment.updateLocale("de", {
        week: {
            dow: 1,
            doy: 4,
        },
    });
    const localizer = momentLocalizer(moment) // or globalizeLocalizer

    let EventHolder = [];
    if(response !== true && typeof response !== "boolean" && response?.length > 0) {
        for(let re=0;re<response.length;re++) {
            EventHolder.push(
                {
                    "title": response[re].regard,
                    "start": new Date(response[re].created_at),
                    "end":   new Date((response[re].due_on === null ? response[re].created_at : response[re].due_on)),
                    "allday": true,
                    "link":"/myprojects/task/single/"+response[re].project_id+"/"+response[re].task_id
                }
            )
        }
    }

    const messages = {
        allDay: 'Alle Tage',
        previous: '<',
        next: '>',
        today: 'Heute',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        agenda: 'Agenda',
        date: 'Datum',
        time: 'Zeit',
        event: 'Event'
    }
    const errorFormswimelane = (error:any,close:any,mode?:any)=> {
        if(mode !== undefined && mode !== null) {
            close(mode)
        } else {
            close()
        }
    }

    const setterEditor = (id:any,id2:any) => {
        (document.getElementById(id) as HTMLDivElement).style.display = 'block';
        (document.getElementById(id2) as HTMLDivElement).style.display = 'none'
    }

    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }

    const CalenderNavigate = (e:any) => {
        navigate(e.link)
    }

    const GrantClick = (task: Task) => {
        navigate("/myprojects/task/single/"+id+"/"+task.id );
    };
    let PrioHolder = [];
    let PrioEditHolder:any = []
    let regex = /[A-Z]/ig;
    if (typeof Prio !== "boolean" && Prio?.length > 0) {
        for (let c = 0; c <Prio.length; c++) {

            PrioEditHolder.push(
                <option value={Prio[c].name.replace(regex,'')}>{Prio[c].name}</option>
            )

            PrioHolder.push(
                <>
                    <div className="agile-row table-border-left" style={{background: Prio[c].color}}>
                        <div className="table-list-details agile-table-list-title">
                            <strong>{Prio[c].name}</strong>
                        </div>
                        <div className="table-list-details agile-table-list-details p-0 m-0 text-center">
                            <ul className="p-0 m-0">
                                <li className="">{Prio[c].underline}</li>
                            </ul>
                        </div>
                    </div>
                </>
            )
        }
    }
    if(typeof singleProject !== "boolean" && singleProject?.length > 0) {
        return (
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>

                    <h3 id={'top'}>{singleProject[0].title}</h3>
                    <Link className={'btn btn-info float-start'}
                          state={{ projectid: singleProject[0].parent }} to={(singleProject[0].parent === "0" ? '/myprojects/new/projects/1' : '/myprojects/new/projects/1/' + singleProject[0].parent + '/test')}
                          style={{background: 'white', borderColor: '#23AAE1', color: '#23AAE1'}}>
                        {IntlMessageValue('de', 'common.back', Store)}
                    </Link>
                    <Search navigate={navigate}/>
                    {refresh(setFetchData,IntlMessageValue('de', 'common.reloadPage', Store))}
                    <DynamikOffCanvas
                        openButtonLabel={<><span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                            {IntlMessageValue('de', 'task.add.new', Store)}</>}
                        saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                        closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                        title={IntlMessageValue('de', 'task.add.new', Store)}
                        ClassOpenButton={'float-end'}
                        ClassSaveButton={'float-end'}
                        body={<>

                            <div className="modal-dialog" style={{marginRight: '-23px'}}>

                            </div>
                            {PielersForm(FormModal)}
                            <Button variant="outline-primary"
                                    onClick={() => setterEditor('htmleditor', 'markdowneditor')}>HTML Editor</Button>
                            <Button variant="outline-primary"
                                    onClick={() => setterEditor('markdowneditor', 'htmleditor')}>Markdown
                                Editor</Button>

                            <div id={'htmleditor'} style={{display: 'block'}}>
                                <SunEditor
                                    key={'test'}

                                    name={'description'}
                                    setContents={value}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'450px'}
                                    onChange={(value) => setEditorContent(value)}
                                />
                            </div>
                            <div id={'markdowneditor'} style={{display: 'none'}} data-color-mode="light">
                                <MarkdownEditor
                                    value={value.replaceAll('<p>', '').replaceAll('</p>', '')}
                                    height={'450px'}
                                    visible={true}
                                    onChange={(value, viewUpdate) => setEditorContent(value, true)}

                                />

                            </div>
                            <br/>
                            <Row>
                                <Col className={'col-6'}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label><IntlMessage messageId="common.ticket.dueOn" Store={Store} /></Form.Label>
                                        <Form.Control type="date" name={'due_on'}  />
                                    </Form.Group>
                                </Col>
                                <Col className={'col-6'}>
                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                        <Form.Label>{IntlMessageValue('de', 'common.attach.document', Store)}</Form.Label>
                                        <Form.Control id={'fileuploadAttachment'} name={'file'} type="file" multiple />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'col-4'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.ticket.priority', Store)}</InputGroup.Text>
                                        <Form.Select aria-label="Default select example" name={'priority'}
                                                     required={true}>
                                            <option label={IntlMessageValue('de', 'common.priority.default', Store)} value=""></option>
                                            {PrioEditHolder}
                                        </Form.Select>
                                        <InputGroup.Text id="basic-addon1" style={{cursor:'pointer'}}><span
                                            className="material-icons float-end"
                                            onClick={(e)=> switchdisplay('MoveElement',null,null,true)}>
                                    info
                                    </span></InputGroup.Text>
                                    </InputGroup>

                                </Col>
                                <Col className={'col-6'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1"><IntlMessage Store={Store} messageId="common.ticket.responsibility" /></InputGroup.Text>
                                        <Form.Select aria-label="Default select example" name={'responsibility'}
                                                     required={true}>
                                            <option label={IntlMessageValue('de', 'common.responsibility.select', Store)} value=""></option>
                                            <option value="assign a user">{IntlMessageValue('de', 'common.responsibility.notAssgin', Store)}</option>
                                            {UserHolder}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col className={'col-2'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.ticket.color', Store)}</InputGroup.Text>
                                        <Form.Control
                                            type="color"
                                            name={'color'}
                                            defaultValue={'#ffffff'}
                                            id={'saveSwimelanecolor1'}
                                        />
                                    </InputGroup>

                                </Col>
                            </Row>
                            <AddedColor state={state} Store={Store} cid={'saveSwimelanecolor1'} color={ColorHolder} setState={setState} />
                            <Form.Control name={'column_id'} type="hidden"
                                          defaultValue={(selectcolumn !== 0 ? selectcolumn : 2)}/>
                            <Form.Control name={'swimlane_id'} type="hidden"
                                          defaultValue={(selectswimelane !== 0 ? selectswimelane : firstswimelane)}/>
                            <Form.Control name={'creator'} type="hidden"
                                          defaultValue={localStorage.getItem('username') as string}/>
                        </>}
                        handlesubmit={handlesubmit}
                        handleShow={handleShow}
                        show={show}
                        id={'TaskNew'}
                        setShow={setShow}
                        handleClose={CloseDynmaikModal}
                    />
                    <Button variant="outline-primary" className={'float-end ml-3'}
                            style={{position: 'relative', right: '5px', zIndex: '96'}}
                            onClick={(e) => AddedFunc(null, null, 'addDialogSwimelanes')}>
                <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                </span>
                        {IntlMessageValue('de', 'projects.button.new.swimlane', Store)}
                    </Button>
                    <br style={{clear: 'both'}}/>
                    <br/>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.title', Store)}</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'regard', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.description', Store)}</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'description', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Instanz</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'instanz', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">{IntlMessageValue('de', 'common.ticket.responsibility', Store)}</InputGroup.Text>
                                <Form.Control type="text"
                                              onChange={(e) => FilterResponse(response, setFetchSortData, setFetchData, 'responsibility', e, filter, setfilter)}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <br/>
                    <BoardTableSwitcher boardId={'board'} TableID={'TableMakerSupport'} HistoryID={'history'} CalenderID={'CalenderElement'}
                                        archiv={setArchiv} del={setDel} calender={setCalender}/>
                    <br/>
                    <div id={'board'} style={{display: 'block'}}>
                        <Row>
                            {buildSwimelanes}
                        </Row>

                    </div>
                    <TableMakerSupport id={'TableMakerSupport'}
                                       Configuration={Configuration}
                                       responseOld={response}
                                       response={fetchSortData}
                                       setFetchSortData={setFetchSortData}
                                       setFetchData={setFetchData}
                                       navigate={navigate}
                                       url={'/myprojects/task/single/[project_id]/[task_id]'}
                                       params={['project_id', 'task_id']}
                    />
                    <div id={'history'} style={{display: 'none', padding: '20px'}}>
                        <div className={'tasktimeline'}>
                            <p><strong>Task Timeline</strong> <span
                                style={{color: '#23AAE1', textDecoration: 'underline'}}>History</span></p>
                        </div>
                        <Card style={{borderRadius: '0px'}}>

                            <Card.Body>
                                <section>
                                    <ul className="timeline">
                                        {BuildLastActivities}
                                    </ul>
                                </section>
                            </Card.Body>

                        </Card>
                    </div>
                    <div id="CalenderElement" style={{display:(calender === 0 ? 'none' : 'block'),padding: '20px',marginTop: '-50px'}} >
                        <InputGroup className="mb-3" >
                            <InputGroup.Text id="calender_label" className={'bg-primary'} onClick={(e:any) => collapse(e, 'calender',false,['GrantDia'],true,setFetchData)}>
                                <span className="material-icons" style={{verticalAlign:'middle'}}>
                                    calendar_month
                                </span>
                            </InputGroup.Text>
                            <InputGroup.Text id="GrantDia_label" className={'bg-primary-subtle'} onClick={(e:any) => collapse(e, 'GrantDia',false,['calender'],true,setFetchData)}>
                                 <span className="material-icons" style={{verticalAlign:'middle'}}>
                                    insights
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                        <div id={'calender'} className={'d-block'}>
                            <Calendar
                                messages={messages}
                                localizer={localizer}
                                /*startAccessor="start"
                                endAccessor="end" */
                                popup={true} defaultView={'week'}
                                events={EventHolder}
                                onDoubleClickEvent={(e:any)=>CalenderNavigate(e)}
                                style={{ minHeight: 'calc(85vh)',maxHeight: 'calc(100vh)',minWidth:'100%', maxWidth: "1000px", margin:" 0 auto", background:'#EFEFEF' }}
                            />
                        </div>
                        <CreateGrantDiagramm ressource={response} id={'GrantDia'} GrantClick={GrantClick} setFetchData={setFetchData}  className={'d-none'}/>
                    </div>
                    <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                         id="addDialogMember"
                         style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>
                        <div className="offcanvas-body">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="modal-title h4">Added Team Member</div>
                                        <button type="button" className="btn-close" aria-label="Close"
                                                onClick={() => CloseMember()}/>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <input name={'TaskId'} type="hidden" id="TaskId"
                                                           className="form-control" value=""/>
                                                    <input name={'ProjectId'} type="hidden" id="ProjectId"
                                                           className="form-control" value=""/>
                                                    <Form.Select name={'MemberId'} aria-label="Default select example">
                                                        <option></option>
                                                        <option value="1">Max Mustermann</option>
                                                        <option value="2">John Doe</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={() => CloseMember()}>Close
                                        </button>
                                        <button type="button" className="btn btn-primary float-end">Added Member
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                         id="addDialogSwimelanes"
                         style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>
                        <div className="offcanvas-body">
                            <div className="modal-dialog">
                                <Form
                                    id={'form001'}
                                    className={'formControl'}
                                    noValidate
                                    onSubmit={(e) =>
                                        saveSwimelane(e, CloseMember, 'addDialogSwimelanes')
                                    }
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className="modal-title h4">{IntlMessageValue('de', 'common.ticket.addSwimlane', Store)}</div>
                                            <button type="button" className="btn-close" aria-label="Close"
                                                    onClick={() => CloseMember('addDialogSwimelanes', [{
                                                        id: 'saveSwimelanename',
                                                        value: ''
                                                    }, {id: 'saveSwimelanecolor', value: '#10B82B'}])}/>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3">
                                                        <Row>
                                                            <Col className={'col-10'}> <Form.Group className="mb-3"
                                                                                                   controlId="exampleForm.ControlInput1">
                                                                <Form.Label>{IntlMessageValue('de', 'common.name', Store)}</Form.Label>
                                                                <Form.Control type="text" id={'saveSwimelanename'}
                                                                              name={'name'} required={true}/>
                                                            </Form.Group></Col>
                                                            <Col className={'col-2'}> <Form.Label
                                                                htmlFor="exampleColorInput">{IntlMessageValue('de', 'common.ticket.color', Store)}</Form.Label>
                                                                <Form.Control
                                                                    type="color"
                                                                    name={'color'}
                                                                    id={'saveSwimelanecolor'}
                                                                    defaultValue="#10B82B"
                                                                /></Col>
                                                        </Row>
                                                        <input name={'ProjectId'} type="hidden" id="ProjectId"
                                                               className="form-control" defaultValue={id}/>
                                                        <AddedColor state={state} Store={Store} cid={'saveSwimelanecolor'} color={ColorHolder} setState={setState} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary"
                                                    onClick={() => CloseMember('addDialogSwimelanes', [{
                                                        id: 'saveSwimelanename',
                                                        value: ''
                                                    }, {id: 'saveSwimelanecolor', value: '#10B82B'}])}>{IntlMessageValue('de', 'common.close', Store)}
                                            </button>
                                            <button type="submit" className="btn btn-primary float-end">{IntlMessageValue('de', 'common.save', Store)}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                         id="addDialogdynamik"
                         style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>

                        <div className="offcanvas-body">
                            <div id="injectModalTask"></div>
                        </div>
                    </div>

                    <InformationTicket AllCPS={AllCPS} Prios={Prio} />

                </div>
            </>
        )
    } else {
        return(
            <></>
        )
    }
}

export default MyTask;


export  const savearchiv = (e:any,id:any,ticketID:any,archiv:any,setFetchData:any) => {
    e.preventDefault()
    let data = e.currentTarget

    var raw = JSON.stringify({
        lastupdatefrom: localStorage.getItem('username'),
        archiv:archiv
    })

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }
    fetch(ApiRoot()+'yoptimize/transfer/'+id+'/task/'+ticketID+'/archiv/', requestOptions)
        .then((response) => response.text())
        .then((result) =>  succesArchive(setFetchData))
        .catch((error) => console.log(error))
}

const succesArchive = (setFetchData:any) => {
    setFetchData(Date.now())
    notify_save()
}

export  const savedelete = (e:any,id:any,ticketID:any,del:any,setFetchData:any) => {
    e.preventDefault()
    let data = e.currentTarget

    var raw = JSON.stringify({
        lastupdatefrom: localStorage.getItem('username'),
        delete:del
    })

    var requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer ' +
                JSON.parse(localStorage.getItem('token') as string)
        },
        body: raw
    }

    fetch(ApiRoot()+'yoptimize/transfer/'+id+'/task/'+ticketID+'/del/', requestOptions)
        .then((response) => response.text())
        .then((result) =>  succesArchive(setFetchData))
        .catch((error) => console.log(error))
}


export const AddedColor = (props:{cid:any,state:any,setState:any,Store:any,color?:any}) => {

    let color;

    if(props.color !== undefined && props.color !== null) {
        color = props.color
    } else {
        color = ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8']
    }

    let colorHolder = [];



    for(let c= 0; c< props.color.length;c++) {
        colorHolder.push(
            <>
                <div
                    className={'colorPickergeneral'} >
                    <span>
                        <div data-title={props.color[c].title} className={'colorPickerColor'}
                             style={{boxShadow: props.color[c].color+' 0px 0px 0px 15px inset'}}
                             onClick={(e)=>ChangeColor(props.color[c].color,props.cid)}
                        />
                    </span>
                </div>
            </>
        )
    }

    const ChangeColor = (e:any,fid:any) => {
        props.setState({ background: e })

        let Colorfield = document.getElementById(fid) as HTMLInputElement;
        if(Colorfield !== null) {
            Colorfield.value = e

        }
    }


    return (
        <>
            <Form.Label>{IntlMessageValue('de', 'common.cps.card', props.Store)}</Form.Label>
            {/*<CirclePicker  color={ props.state.background } width={'400'}
                          colors={color}
                          onChange={(e)=> ChangeColor(e,props.cid) }  />*/}

            <div className="circle-picker circle-picker-general">
            {colorHolder}
            </div>
            <br/>
        </>
    )
}

export const colorSwitcher = (code:any,isrgb:any) => {
    const wc_hex_is_light = (color:string) => {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }

    const componentToHex = (c:any) => {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    const rgbToHex = (r:any, g:any, b:any) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    if(isrgb === true) {
        let rgb = code.replace('rgb','').replace('(','').replace(')','')
        return wc_hex_is_light(rgbToHex(parseInt(rgb.split(',')[0]),parseInt(rgb.split(',')[1]),parseInt(rgb.split(',')[2])))
    } else {
        return wc_hex_is_light(code)
    }

}


export const InformationTicket = (props:{AllCPS:any,Prios?:any}) => {

    let Top = (localStorage.getItem('moveInformationtop') !== null ? localStorage.getItem('moveInformationtop') as string : '435px')

    let Left = (localStorage.getItem('moveInformationleft') !== null ? localStorage.getItem('moveInformationleft') as string  : '45px')

    let Display = (localStorage.getItem('moveInformationdisplay') !== null ? localStorage.getItem('moveInformationdisplay') as string  : 'none')




    let ColorstativHolder = [];
    if (typeof props.AllCPS !== "boolean" && props.AllCPS?.length > 0) {
    ColorstativHolder.push(<>
        <div  className="table-list-header"
             style={{color:'white',background:'#666666',height:'40px',padding: '8px'}}>
        <strong style={{verticalAlign: 'middle'}}>Card color</strong>
            <span className="material-icons float-end" style={{cursor:'pointer'}} onClick={() => switchdisplay('MoveElement',null,null,true)}>
                                close
                            </span>
        </div>
        </>)


        for (let c = 0; c < props.AllCPS.length; c++) {
            ColorstativHolder.push(
                <>
                    <div className="agile-row table-border-left agile-bg-cream" style={{background:props.AllCPS[c].color}}>
                        <div className="table-list-details agile-table-list-title">
                            <strong style={{color: (colorSwitcher(props.AllCPS[c].color,false) === true ? 'black' : 'white')}}>{props.AllCPS[c].title}</strong>
                        </div>

                    </div>
                </>
            )
        }
    }
    let PrioHolder = [];
    if (typeof props.Prios !== "boolean" && props.Prios?.length > 0) {
        PrioHolder.push(<>
            <div id="MoveElementHeader" className="table-list-header"
                 style={{color: 'white', background: '#666666', height: '40px'}}><strong
                style={{verticalAlign: 'middle'}}>Prioritäten</strong>
                <span className="material-icons float-end" style={{cursor: 'pointer'}}
                      onClick={() => switchdisplay('MoveElement', null, null, true)}>
                                close
                            </span>

            </div>
        </>)
        for (let c = 0; c < props.Prios.length; c++) {

            PrioHolder.push(
                <>
                    <div className="agile-row table-border-left" style={{background: props.Prios[c].color}}>
                        <div className="table-list-details agile-table-list-title">
                            <strong>{props.Prios[c].name}</strong>
                        </div>
                        <div className="table-list-details agile-table-list-details p-0 m-0 text-center">
                            <ul className="p-0 m-0">
                                <li className="">{props.Prios[c].underline}</li>
                            </ul>
                        </div>
                    </div>
                </>
            )
        }
    }
    return(
        <>
            <div id="MoveElement" style={{display: Display,position:'absolute',top:Top,left:Left,zIndex:'9999',width:'95px'}}>
                <div id="AgileTaskPriorities" className="table-list tooltip-agile-row"
                     style={{display: 'block',height:'450px',overflowY:'scroll',overflowX:'hidden',margin:'0'}}>
                        {PrioHolder}
                        {ColorstativHolder}
                </div>
            </div>
        </>
    )
}

export const MoveElement = () => {
    dragElement(document.getElementById("MoveElement"));

    function dragElement(elmnt:any) {
        if(elmnt === null) {
            return;
        }
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header") as HTMLDivElement !== null) {
            // if present, the header is where you move the DIV from:
            (document.getElementById(elmnt.id + "header") as HTMLDivElement).onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e:any) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e:any) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            localStorage.setItem('moveInformationtop',elmnt.style.top)
            localStorage.setItem('moveInformationleft',elmnt.style.left)
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }
}


export const CreateGrant = (props:{ressource:any,id:any,className:any}) => {
    const columns = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
    ];

    let RowHolder = [];

    for(let re=0;re<props.ressource.length;re++) {

        let due_on = new Date((props.ressource[re].due_on === null ? props.ressource[re].created_at : props.ressource[re].due_on))
        if(props.ressource[re].due_on === null) {
            due_on = new Date(due_on.getTime() + 86400000); // + 1 day in ms
            due_on.toLocaleDateString();
        }

        RowHolder.push(
            [
                props.ressource[re].task_id,
                props.ressource[re].regard,
                props.ressource[re].priority,
                new Date(props.ressource[re].created_at),
                due_on,
                null,
                100,
                null,
            ]
        )
    }

    const rows = RowHolder;
    const options = {
        height:(props.ressource.length * 30) + 100,
        width:1500,
        gantt: {
            trackHeight: 30,
            palette: [
                {
                    "color": "#db4437",
                    "dark": "#a52714",
                    "light": "#f4c7c3"
                },
                {
                    "color": "#f2a600",
                    "dark": "#ee8100",
                    "light": "#fce8b2"
                },
                {
                    "color": "#0f9d58",
                    "dark": "#0b8043",
                    "light": "#b7e1cd"
                },
                {
                    "color": "#ab47bc",
                    "dark": "#6a1b9a",
                    "light": "#e1bee7"
                },
                {
                    "color": "#00acc1",
                    "dark": "#00838f",
                    "light": "#b2ebf2"
                },
                {
                    "color": "#5e97f6",
                    "dark": "#2a56c6",
                    "light": "#c6dafc"
                },
            ]
        },

    };
    const data = [columns, ...rows]
    return(
        <>
            <div id={props.id} className={props.className} style={{overflow:'hidden'}}>
                <Chart
                    chartType="Gantt"
                    width="1500px"
                    height="50%"
                    data={data}
                    options={options}
                    style={{minWidth:'1500px',width:'1500px'}}
                />

            </div>
        </>
    )
}
