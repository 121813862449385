import { useEffect, useState } from 'react'
import { memberApiType } from '../request/memberApi.interface'
import { error } from '../../../../@WUM/core/component/const/error.services'
import {ticket} from "../request/ticket";
import {projectApiType} from "../request/projectAPI.interface";
import {internalApiTYpe} from "../request/internal.Api,interface";
import {LastActivitiesApiTYpe} from "../request/LastActivitiesApi.interface";
import {taskColumnApiTYpe} from "../request/taskColumn.Api,interface";
import {taskSwimelaneApiTYpe} from "../request/taskSwimelanes.Api,interface";
import {responsibilityApiTYpe} from "../request/responsibility.Api,interface";
import {projectDashboardApiType} from "../request/projectDashboardAPI.interface";
import * as actionSingleTask from "../../modules/new/store/Actions/Singletask/SingleTaskAction";
import {DuoOnApiType} from "../request/DuoOn.interface";
import {reportsApiTYpe} from "../request/reports.Api,interface";
import {stateApiTYpe} from "../request/state.Api,interface";
import {CpsApiType} from "../request/Cps.interface";
import {PrioApiType} from "../request/Prio.interface";
import {LabelsApiType} from "../request/Labels.interface";
import {checkLogin} from "../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseAllProject = (id: any) => {
    const [posts, setPosts] = useState<projectApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsFetch()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllCPS = (id: any) => {
    const [posts, setPosts] = useState<CpsApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsCPS()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllPrio = (id: any) => {
    const [posts, setPosts] = useState<PrioApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsPRio()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllLabels = (id: any) => {
    const [posts, setPosts] = useState<LabelsApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsLabels()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}


export const ResponseAllProjectActivityREcords = (id: any) => {
    const [posts, setPosts] = useState<projectApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsactivityRecords()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseDashboardProject = () => {
    const [posts, setPosts] = useState<projectDashboardApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getDashboardPosts()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}

export const ResponseSingleProject = (id: any) => {
    const [posts, setPosts] = useState<projectApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsSingle(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllProjectDashboard = (id: any,archiv:any = 0,del:any=0) => {
    const [posts, setPosts] = useState<projectApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsDashboard(archiv,del)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,archiv,del])

    return isError ? isError : posts
}

export const ResponseAllProjectParent = (id: any,fetch:any,archiv:any = 0,del:any=0) => {
    const [posts, setPosts] = useState<projectApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsParent(id,archiv,del)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetch,archiv,del])

    return isError ? isError : posts
}

export const ResponseAllTaskInternall = (id: any) => {
    const [posts, setPosts] = useState<internalApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskInternal(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllLastActivities = (id: string | undefined,taskid:any,fetch:any) => {
    const [posts, setPosts] = useState<LastActivitiesApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getAPostLastActivities(id,taskid)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,taskid,fetch])

    return isError ? isError : posts
}

export const ResponseAllLastActivitiesProject = (id: string | undefined,fetch:any) => {
    const [posts, setPosts] = useState<LastActivitiesApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getAPostLastActivitiesProject(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetch])

    return isError ? isError : posts
}

export const ResponseAllLastActivitiesAll = (fetch:any) => {
    const [posts, setPosts] = useState<LastActivitiesApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getAPostLastActivitiesAll()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [fetch])

    return isError ? isError : posts
}

export const ResponseDashbaordProject = (id: any) => {
    const [posts, setPosts] = useState<projectApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsProjectDashbaord()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseDashbaordTask = (id: any) => {
    const [posts, setPosts] = useState<memberApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsTaskDashbaord()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllTask = (id: string | undefined,time:any,archiv:any = 0,del:any=0) => {
    const [posts, setPosts] = useState<memberApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTask(id,archiv,del)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,time,archiv,del])

    return isError ? isError : posts
}

export const ResponseAllTaskColumn = (time:any) => {
    const [posts, setPosts] = useState<taskColumnApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskColumn()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [time])

    return isError ? isError : posts
}

export const ResponseAllTaskSwimelanes = (id:any,time:any) => {
    const [posts, setPosts] = useState<taskSwimelaneApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskSwimelanes(id)
                .then((data) => {
                    checkLogin(data)
                    localStorage.setItem('swimlanes',JSON.stringify(data))
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,time])

    return isError ? isError : posts
}

export const ResponseAllProjektSwimelanes = (time:any) => {
    const [posts, setPosts] = useState<taskSwimelaneApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskSwimelanesProject()
                .then((data) => {
                    checkLogin(data)
                    localStorage.setItem('swimlanesproject',JSON.stringify(data))
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [time])

    return isError ? isError : posts
}

export const ResponseAllSubProjektSwimelanes = (id:any,time:any) => {
    const [posts, setPosts] = useState<taskSwimelaneApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskSwimelanesSubProject(id)
                .then((data) => {
                    checkLogin(data)
                    localStorage.setItem('swimlanesSubproject',JSON.stringify(data))
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [time])

    return isError ? isError : posts
}

export const ResponseAllSubProjektSwimelanesLocal = (id:any,time:any) => {
    const [posts, setPosts] = useState<taskSwimelaneApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskSwimelanesSubProject(id)
                .then((data) => {
                    checkLogin(data)
                    localStorage.setItem('swimlanesSubprojectLocal',JSON.stringify(data))
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [time])

    return isError ? isError : posts
}

export const ResponseAllTResponsibility = (time:any) => {
    const [posts, setPosts] = useState<responsibilityApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllResponsibility()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [time])

    return isError ? isError : posts
}

export const ResponseAllProjectColumn = (time:any) => {
    const [posts, setPosts] = useState<taskColumnApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskProject()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [time])

    return isError ? isError : posts
}

export const ResponseAllTaskState = (fetchDataID:any) => {
    const [posts, setPosts] = useState<stateApiTYpe[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsAllTaskState()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [fetchDataID])

    return isError ? isError : posts
}

export const ResponseTaskDetail = (id: string | undefined,taskid:any,fetch:any,setValue:any,commentEdit:any,dispatch:any) => {
    const [posts, setPosts] = useState<memberApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getAPostTask(id,taskid)
                .then((data) => {
                    checkLogin(data)
                    /*console.log(data);*/
                    setPosts(data)

                    setValue(data[0].description)

                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,taskid,fetch,commentEdit])

    return isError ? isError : posts
}

export const ResponsemyTasks = (responsibility:any,fetchData:any) => {
    const [posts, setPosts] = useState<DuoOnApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsDuo(responsibility)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [responsibility,fetchData])

    return isError ? isError : posts
}
export const ResponseReports = (startdate:any,enddate:any,fetchData:any) => {
    const [posts, setPosts] = useState<reportsApiTYpe>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticket
                .getPostsreports(startdate,enddate)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [startdate,enddate,fetchData])

    return isError ? isError : posts
}
