import React, { useState } from 'react'
import { SpinnerJSX } from '../../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { ContentSwitch, IntlMessage } from '../../../../@WUM/core/Elements/index'
import {
    ResponseallTaxonLimit,
    ResponseallTaxonTree
} from '../../Taxon/API/response/taxon/Taxon'
import {
    ResponseAllShipingTypes,
    ResponseAllTax,
    ResponseallTaxon,
    ResponseTaxonDelete
} from '../../../shop/products/API/response/product/Product'
import { allTaxonApiType } from '../../../shop/products/API/request/Product/allTacxonApi.interface'
import { TaxApiType } from '../../../shop/products/API/request/Product/TaxApi.interface'
import { ShippingApiType } from '../../../shop/products/API/request/Product/ShippingApi.interface'
import { memberApiType } from '../../Member/API/request/member/memberApi.interface'
import { ResponseAllMerchant } from '../../Member/API/response/member/member'
import CustomizedTreeView from '../../../../@WUM/core/component/const/TreeView'
import Row from 'react-bootstrap/Row'
import NewButton from '../../../../@WUM/core/component/const/new'
import { useSelector } from 'react-redux'

const TaxonOverview = (value: any) => {
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    let limit: any
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = () => {
        history(-1)
    }

    if (deleteID) {
        ResponseTaxonDelete(deleteID)
        routeChange()
    }
    const debug = (
        searchValue: string,
        modalShow: boolean,
        modalData: any[],
        allTaxon: any
    ) => {
        return
    }
    const [searchValue, setSearchValue] = useState('')
    const [search, setSearch] = useState<boolean>(false)
    let response
    if (process.env.REACT_APP_AGRICA !== 'True') {
        response = ResponseallTaxonLimit(limit, searchValue)
    } else {
        response = ResponseallTaxonTree()
    }

    const allTaxon = ResponseallTaxon()
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    debug(searchValue, modalShow, modalData, allTaxon)
    const [mode, setMode] = useState('cart')
    const destination = 'Taxon'
    const debugs = (
        allTaxon: allTaxonApiType[] | boolean,
        tax: TaxApiType[],
        Shipping: ShippingApiType[],
        AllMerchant: memberApiType[] | boolean
    ) => {
        return
    }
    const Shipping = ResponseAllShipingTypes()
    const tax = ResponseAllTax()
    const AllMerchant = ResponseAllMerchant()
    debugs(allTaxon, tax, Shipping, AllMerchant)

    const CardsConfiguration = {
        data: {
            ressource: response,
            img: '',
            dontStatus: true,
            dontStatus_label: (
                <IntlMessage Store={Store} messageId="taxon.label" />
            ),
            status: 'visible',
            statusActive: (
                <IntlMessage Store={Store} messageId="common.activated" />
            ),
            statusDeactive: (
                <IntlMessage Store={Store} messageId="common.deactivated" />
            ),
            link: 'translation.slug',
            prelink: 'http://myhomice-f2.pielers.de/magazin/[param]/',
            prelinksearch: 'translation.slug',
            title: 'translation.name',
            description: 'translation.slug',
            parentShow: true,
            treeShow: true,
            countMerchant: false,
            countProducts: false,
            tree_root: 'tree_root',
            parentLabel: (
                <IntlMessage Store={Store} messageId="taxon.parentLabel" />
            ),
            router: 'id',
            preroute: '/Taxon/Detail/',
            edit: <IntlMessage Store={Store} messageId="taxon.edit" />,
            delete: true,
            deleteLink: '/Taxon/Delete/' + page + '/[ID]',
            Dontvisited: true
        }
    }

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['id', 'translation.name', 'translation.slug'],
            type: ['string', 'string', 'string']
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="common.id" />,
            <IntlMessage Store={Store} messageId="common.title" />,
            <IntlMessage Store={Store} messageId="common.slug" />
        ],
        tableHeaderWidth: ['30%', '30%', '30%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: ['/Taxon/Detail', '/Taxon/Delete'],
            menuParams: ['id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }

    let content

    if (process.env.REACT_APP_AGRICA !== 'True') {
        content = (
            <>
                <ContentSwitch
                    pagenew={'new'}
                    destinationnew={'Taxon'}
                    tonew={'new'}
                    text={
                        <IntlMessage
                            Store={Store}
                            messageId="common.create_new_taxon"
                        />
                    }
                    response={response}
                    CardsConfiguration={CardsConfiguration}
                    mode={mode}
                    Configuration={Configuration}
                    setMode={setMode}
                    destination={destination}
                    page={page}
                    to={'overview'}
                    setSearchValues={setSearchValue}
                    setSearchs={setSearch}
                    search={search}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalData={modalData}
                    setModalData={setModalData}
                    searchfield={''}
                    allTaxon={allTaxon}
                    _handleKeyFilters={''}
                    _handleEnter={''}
                    filter={false}
                    Store={Store}
                    SearchParam={'?translation.name'}
                />
            </>
        )
    } else {
        content = (
            <>
                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    <Row>
                        <NewButton
                            history={history}
                            page={'new'}
                            destination={'Taxon'}
                            to={'new'}
                            text={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.create_new_taxon"
                                />
                            }
                        />
                    </Row>
                </div>
                <CustomizedTreeView response={response} />
            </>
        )
    }

    if (response !== true && response.length > 0) {
        return (
            <>{content}</>
            /*    */
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default TaxonOverview
