import React, { useState } from 'react'
import { SpinnerJSX } from '../../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { ContentSwitch, IntlMessage } from '../../../../@WUM/core/Elements/index'
import {
    ResponseallTaxLimit,
    ResponseTaxDelete
} from '../../../shop/products/API/response/product/Product'
import { useSelector } from 'react-redux'

const TaxOverview = (value: any) => {
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    let limit: any
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }

    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths: string) => {
        history(-1)
    }

    if (deleteID) {
        ResponseTaxDelete(deleteID)
        routeChange('/Tax/overview/1')
    }
    const debug = (
        page: string | undefined,
        searchValue: string,
        modalShow: boolean,
        modalData: any[]
    ) => {
        return
    }
    const [searchValue, setSearchValue] = useState('')
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseallTaxLimit(limit, searchValue)
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    debug(page, searchValue, modalShow, modalData)
    const [mode, setMode] = useState('cart')
    const destination = 'Tax'

    const CardsConfiguration = {
        data: {
            ressource: response,
            img: '',
            dontStatus: true,
            dontStatus_label: (
                <IntlMessage Store={Store} messageId="tax.label" />
            ),
            status: 'visible',
            statusActive: (
                <IntlMessage Store={Store} messageId="common.activated" />
            ),
            statusDeactive: (
                <IntlMessage Store={Store} messageId="common.deactivated" />
            ),
            link: 'slug',
            prelink: 'http://myhomice-f2.pielers.de/magazin/[param]/',
            prelinksearch: 'translation.slug',
            title: 'name',
            description: 'description',
            router: 'id',
            preroute: '/Tax/Detail/',
            edit: <IntlMessage Store={Store} messageId="tax.edit" />,
            delete: true,
            deleteLink: '/Tax/Delete/' + page + '/[ID]',
            Dontvisited: true
        }
    }

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['id', 'name', 'description'],
            type: ['string', 'string', 'string']
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="common.id" />,
            <IntlMessage Store={Store} messageId="common.title" />,
            <IntlMessage Store={Store} messageId="common.slug" />
        ],
        tableHeaderWidth: ['30%', '30%', '30%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: ['/Tax/Detail', '/Tax/Delete'],
            menuParams: ['id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }

    if (response !== true && response.length > 0) {
        return (
            <>
                <ContentSwitch
                    pagenew={'new'}
                    destinationnew={'Tax'}
                    tonew={'new'}
                    text={
                        <IntlMessage
                            Store={Store}
                            messageId="common.create_new_tax"
                        />
                    }
                    response={response}
                    CardsConfiguration={CardsConfiguration}
                    mode={mode}
                    Configuration={Configuration}
                    setMode={setMode}
                    destination={destination}
                    page={page}
                    to={'overview'}
                    setSearchValues={setSearchValue}
                    setSearchs={setSearch}
                    search={search}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalData={modalData}
                    setModalData={setModalData}
                    searchfield={''}
                    allTaxon={''}
                    _handleKeyFilters={''}
                    _handleEnter={''}
                    filter={false}
                    Store={Store}
                    SearchParam={'?translation.name'}
                />
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default TaxOverview
